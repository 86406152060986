
.wrapper {
  width: 90%;
  margin: 0 auto;
}

.wrapper > h2 {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #003f70;
}

.wrapper > ul {
  width: 80%;
  margin: 0 auto;
}

.wrapper > ul > li {
  font-size: 1.1rem;
}