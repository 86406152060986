.wrapper {
  width: 90%;
  margin: 1rem auto 0;
}

.wrapper > button {
  margin-top: 1.5rem;
}

.wrapper > h2,
.modal > h2 {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #003f70;
}

.wrapper > ul {
  margin: 1rem auto 0;
  width: 90%;
}

.wrapper > ul svg:hover {
  transform: scale(1.3);
  transition: .2s;
}

.firstLiBlock {
  display: flex;
  font-size: 1.1rem;
}

.firstLiBlock {
  width: 50%;
}

.firstLiBlock > div {
  overflow: hidden;
}

.firstLiBlock > div:first-child {
  width: calc(100% - 150px);
}

.modal {
  min-width: 500px;
}

.modal > label {
  display: block;
}

.modal > label + label {
  margin-top: .5rem;
}

.buttonsBlock {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.buttonsBlock > * + * {
  margin-left: 1rem;
}