.newsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
}

.newsCard {
    width: 49%;
    display: block;
    padding: 0 1rem 1rem 1rem;
    cursor: pointer;
    position: relative;
    margin-bottom: 2rem;

}

.newsCard:hover .delete {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    z-index: 100;
    font-size: 30px;
    color: red;
}

.newsCard:hover .edit {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: .5rem;
    right: 3rem;
    z-index: 100;
    font-size: 30px;
    color: #003f70;
}

.newsCard:hover {
    background-color: #dcf1ff;
    border-radius: 4px;
    transition: .5s;
    box-shadow: 0px 5px 20px 17px rgba(53, 75, 92, 0.05);
}

.newsCard h3 {
    font-size: 1rem;
    margin: 1rem 0;
}

.newsCard a {
    display: block;
    margin: 1rem 0;
    color: #4ac2f1;
    font-size: 20px;
}

.newsCard span {
    font-weight: 600;
}

.dateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dateCounter {
    font-size: 20px;
    line-height: 25px;
}

.dateCounter span {
    font-weight: 600;
    font-size: 25px;
}

.inputBlock {
    margin: 1rem;
}

.inputBlock span {
    position: relative;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #003f70;
    user-select: none;
}

.inputBlock span .plus {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 25px;
    color: #003f70;
    cursor: pointer;
    user-select: none;
}

.inputBlock input {
    font-size: 20px;
    border: 1px solid #003f70;
    padding: 5px 10px;
    /* border-radius: 5px; */
    cursor: pointer;
    outline: none;
}

.risk {
    margin: .5rem .5rem .5rem 0;
    display: block;
}

.link {
    color: #000 !important;
    text-decoration: none;
}

.link > * {
    margin: .5rem 0;
}

.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    margin: 0 auto;    
}

.button {
    background-color: #003f70;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    margin: 1rem;
}


.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.popup {
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    overflow: auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContent {
    width: 100%;
    max-width: 1200px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem 2rem 0rem 2rem; 
    position: relative;
}

.popupContentInner {
    overflow: auto;
    width: 100%;
    margin-top: 1rem;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 1rem;
    font-size: 20px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.popup .inputBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 3rem);
    margin: 1.5rem 0;
    padding: .5rem;
    background: #f9f9f9;
    box-shadow: 3.277px 2.294px 8px 0px rgba(171, 171, 171, 0.01);
    border-radius: 4px;
}

.popup .inputBlockInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);
}

.popup .inputBlock span {
    width: 280px;
}

.popup .inputBlock input {
    width: 100%;
    font-size: 16px;
}

.popup .inputBlock textarea {
    width: 100%;
    resize: none;
    outline: none;
    height: 100px;
    border: 1px solid #003f70;
}

.select {
    width: 60px;
    margin: .5rem;
    padding: .4rem;
    background: #fff;
    border: 1px solid #003f70;
    font-size: 15px;
}

.delete {
    display: none;
}

.edit {
    display: none;
}

@media screen and (max-width: 1024px) {
    .newsCard {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .newsCard {
        width: 50%;
    }
}

@media screen and (max-width: 725px) {
    .newsCard {
        width: 100%;
    }

    .popup .inputBlock {
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
    }

    .popup .inputBlock span {
        margin-bottom: 1rem;
    }
}

.wrapperInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputModal {
    margin: .2rem 0;
}

.inputModalEmpty {
    margin: .2rem 0;
    background-color: red !important;
}

.inputRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trash {
    cursor: pointer;
    font-size: 25px;
    color: red;
    margin-left: .5rem;
    user-select: none;
}

.map {
    width: 300px;
    height: 300px;
}

.number {
    width: fit-content;
    font-size: 1rem;
    display: block;
    background-color: #003f70;
    color: #fff;
    padding: .5rem 1rem;
    border-radius: .2rem;
    margin-right: 1rem;
}

.control {
    width: fit-content;
    color: #fff;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: #e03131;
    padding: .3rem .5rem;
}

.wrapperNum {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}