.wrapper {
    text-align: center;
    font-weight: 600;
    margin: 0 1rem;
}

.wrapper p {
    color: #3d4e86;
    font-size: 3rem;
    margin: 2rem .5rem 1rem .5rem;
}

.wrapper i{
    font-size: 15rem;
    color: #a2dff9;
}