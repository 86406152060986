.bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    width: 80%;
    background-color: #fff;
    padding: 1rem;
    position: relative;
}

.title {
    text-transform: uppercase;
    color: #003f70;
    margin: 1rem 1rem 2rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
}

/* input start */ 

.wrapperInput {
    margin: 1rem 0;
    padding: .5rem 0;
    display: flex;
    flex-direction: column;
    width: 80vw;
}

.inputItem {
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.inputItemName {
    width: 450px;
    text-align: right;
    margin-right: 1rem;
    text-transform: uppercase;
    color: #003f70;
}

.inputItemInput {
    width: 100%;
    padding: .3rem;
    font-size: 14px;
    outline: none;
    border: 1px solid grey;
}

.imgAndInput {
    display: flex;
    align-items: center;
}

.inputBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inputImg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* width: 60%; */
}

.img {
    /* width: 260px; */
    width: 60%;
    margin: 0 .5rem 0 1rem;
}

/* input end */

.wrapperButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.button {
    background-color: #003f70;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    margin: 1rem;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 1rem;
    font-size: 20px;
}