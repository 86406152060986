.map {
    width: 100%;
    height: 100%;
    display: block;
    background-color: red;
    position: relative;
}

.openLogo {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: aliceblue;
    padding: .5rem .1rem;
    font-size: 13px;
    user-select: none;
}