.wrapper {
    width: 100%;
    max-width: 90vw;
    margin: 1rem auto;
}

.title {
    color: #003f70;
    font-size: 1.2rem; 
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    font-weight: 600;
    text-decoration: underline;
    margin-left: 1rem;
}

/* input start */ 

.wrapperInput {
    margin: 1rem 0;
    padding: .5rem 0;
    display: flex;
    flex-direction: column;
    width: 80vw;
}

.inputItem {
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.inputItemName {
    width: 300px;
    text-align: right;
    margin-right: 1rem;
    text-transform: uppercase;
    color: #003f70;
}

.inputItemInput {
    width: calc(100% - 320px);
    padding: .3rem;
    font-size: 14px;
    outline: none;
    border: 1px solid grey;
}

.imgAndInput {
    display: flex;
    align-items: center;
}

.inputBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputImg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.img {
    /* width: 260px; */
    width: 60%;
    margin: 0 .5rem 0 1rem;
}

/* input end */

.button_add {
    background-color: #003f70;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    margin: 0 0 0 auto;
    width: fit-content;
}

.wrapperLoader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
}