.label > p {
    margin-left: .3rem;
    color: #778AAF;
    font-size: 13px;
}

.input {
    margin-top: .3rem;
    background-color: transparent;
    color: #274684;
    font-size: 15px;
    line-height: 18px;
    padding: .4rem;
    outline: none;
    border-radius: 2px;
    border: 1px solid #0000001A;
}

.checkbox {
    display: flex;
    align-items: center;
}


.creatableSelect {
    margin-top: .2rem;
    outline: none;
    border: none;
}

.creatableSelect > div:nth-child(3) {
    outline: none;
    background-color: transparent;
    margin-left: -.6rem;
    transform: scale(.9);
    color: #274684;
    min-width: 200px;
    font-size: 15px;
    line-height: 18px;
    outline: none;
    border-radius: 2px;
    border: 1px solid #0000001A;
}

.error {
    border-color: red;
}

.label .errorText {
    color: red;
    font-size: .7rem;
}
