.clock {
    color: #2671a1;
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.clock span {
    color: #2671a1;
    font-size: 2rem;
}
@media only screen and (max-width: 400px) {
    .clock {
        font-size: 1.2rem;
    }
    .clock span {
        font-size: 30px;
    }
}
