.wrapper {
    height: 100%;
}

.map{
    height: 100%;
    width: 70vw;
    margin: 0 auto;
}

.select {
    z-index: 10;
    width: 100%;
    max-width: fit-content;
    font-size: 1rem;
    padding: .2rem .5rem;
    border-radius: 0;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgb(38, 113, 161);
    color: #fff;
}