.wrapper {
    border: 2px solid rgb(112 166 203);
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffffd4;
    width: 100%;
    max-width: 500px;
}

.wrapper input {
    margin: 0 1.5rem 0 1rem;
    min-width: 300px;
    border: none;
    outline: none;
    width: 100%;
    display: block;
}

.icon {
    color: rgb(112 166 203);
    font-size: 22px;
}

.showIcon {
    position: absolute;
    top: calc(50% - 11px);
    right: 1rem;
    color: grey;
    cursor: pointer;
}

.active {
    color: #195abb;
}

@media screen and (max-width: 500px) {
    .wrapper input {
        margin: 0 1.5rem 0 1rem;
        min-width: auto;
        border: none;
        outline: none;
        width: 100%;
    }
}