.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem .5rem 0 .5rem;
    height: calc(90% - 2rem);
}

.wrapper_clock {
    /* margin-top: 1rem; */
    text-align: center;
    width: 100%;
    height: 100%;
}

.wrapperBlocks {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

@media screen and (max-width: 1024px) {
    .wrapper_clock {
        margin-top: 1rem;
    }    
}

.wrapperInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.alarmList {
    display: flex;
    align-items: center;
}

.alarmList div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    /* margin: .5rem; */
}

.alarmItem {
    background-color: #c9c9c9;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin: 5px;
    user-select: none;
    transition: .2s;
}

.alarmItem__active:hover,
.alarmItem__selected {
    transform: scale(1.1);
}

.alarmItem img {
    width: 23px;
}

.alartImg {
    width: 23px;
    height: auto;
}

.alarmItem__All {
    width: 20px;
    height: 20px;
    padding: 11px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    justify-content: center;
}
  
.alarmItem__hint {
    display: none;
    position: absolute;
    z-index: 5;
    top: -35px;
    left: 0;
    /* transform: translate(-50%, -50%); */
    background-color: #dcf1ff;
    color: #5992c4;
    font-size: 15px;
    padding: .5rem;
    border-radius: 4px;
    width: max-content;
    box-shadow: 3.277px 2.294px 8px 0px rgba(171, 171, 171, 0.004);
}

.alarmItem:hover .alarmItem__hint, .alarmItem__active:hover .alarmItem__hint {
    display: block;
}

.wrapperSummary {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #767676;
    height: 100%
}

.summaryBlock {
    width: 50%;
    position: relative;
    height: 50%;
}

.summaryInnerBlock {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    border: 1px solid #767676;
    padding: .5rem .5rem 1rem 0;
    display: flex;
    transition: .2s;
}

.innerBlockActive {
    height: 150%;
}

.summaryBlock__left {
    width: 65%;
}

.summaryBlock__right {
    width: 35%;
    max-height: 90%;
    overflow: auto;
}

.summaryBlock__right > * {
    display: block;
}

.summaryBlock__right span {
    display: block;
}

.summaryBlock__right span:hover {
    color: rgb(30, 85, 133);
}

.summaryBlock__right > * + * {
    margin-top: .8rem;
}

.summaryBlock span {
    line-height: 1.1;
}

.summary_header {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: space-between;
    padding-left: 1rem;
    margin-right: .5rem;
}

.summary_header_title {
    color: #2671a1;
    text-decoration: underline;
    font-size: 15px;
    margin-right: 3rem;
}

.summary_header_count {
    color: #2671a1;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 22px;
}

.summary_slider {
    display: flex;
}

.summary_title {
    color: #777777;
    font-size: 20px;
    text-decoration: underline;
    width: 100%;
    display: block;
    text-align: left;
    margin-bottom: 5px;
}

.summary_text {
    color: #777777;
    font-size: 18px;
    text-align: justify;
    display: block;
}

.summaryBtn {
    color: #777;
    font-size: 15px;
    text-decoration: underline;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
}

.audioBlock {
    width: 5vw;
}

.audioBlock > img {
    max-width: 35px;
    cursor: pointer;
    transition: .5s;
}

.audioBlock > img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .summaryBlock {
        width: 100%;
        flex-direction: column;
    }
    .summaryBlock__left {
        width: 100%;
    }
    
    .summaryBlock__right {
        width: 100%;
    }
    .wrapperInfo {
        flex-direction: column;
    }

    .wrapper {
        height: 100%;
    }
}

.map {
    width: 100%;
    height: 68%;
    min-height: 19.5vh;
}

@media screen and (max-width: 1100px) {
    .wrapperInfo {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .wrapper {
        height: 100%;
    }
}

@media screen and (max-width: 447px) {
    .summary_header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: auto;
        margin-right: auto;
    }
    
    .summary_header_title {
        font-size: 1.2rem;
        margin: .5rem 0 .5rem;
        text-align: center;
    }
    
    .summary_header_count {
        margin-bottom: .5rem;
    }
    .summaryBlock {
        padding: .5rem .5rem 1rem .5rem;
    }
    .map {
        height: auto;
        min-height: auto;
    }
}

