.newsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
}

.newsCard {
    width: 18%;
    display: block;
    padding: 1rem 1rem 2rem 1rem;
}

.newsCard:hover{
    background-color: #dcf1ff;
    border-radius: 4px;
    transition: .5s;
    box-shadow: 0px 5px 20px 17px rgba(53, 75, 92, 0.05);
}

.newsCard h3 {
    font-size: 1rem;
}

.newsCard a {
    display: block;
    /* margin: 1rem 0; */
    color: #4ac2f1;
    font-size: 1rem;
}

.newsCard span {
    font-weight: 600;
}

.dateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dateCounter {
    width: 87%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 25px;
}

.dateCounter span {
    font-weight: 600;
    font-size: 25px;
}

.inputBlock {
    margin: 1rem;
}

.inputBlock span {
    margin-right: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #003f70;
}

.inputBlock input {
    font-size: 20px;
    border: 1px solid #003f70;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.risk {
    margin: .5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.risk select {
    padding: .2rem;
    height: 2rem;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #003f70;
    border-radius: .3rem;
    margin-left: .5rem;
}

.textarea {
    resize: none;
    width: 100%;
    height: 70px;
}

.icon {
    cursor: pointer;
    color: grey;
    font-size: 20px;
    margin-right: .5rem;
}

.icon:hover {
    color: #003f70;
    transition: .3s;
}

.number {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: #003f70;
    color: #fff;
    /* padding: .2rem; */
    margin-bottom: .5rem;
    border-radius: .2rem;
}

.number_news {
    padding: .2rem;
}

.number_event {
    padding: .2rem;
    background-color: #fff;
    color: #003f70;
    cursor: pointer;
    border: 1px solid #003f70;
    border-radius: 0 .2rem .2rem 0;
}

.number_event a {
    text-decoration: none;

}

.button {
    background-color: #003f70;
    color: #fff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    user-select: none;
    margin: 0.5rem 0 0.5rem 0.5rem;
    border: none;
    font-size: 17px;
}

@media screen and (max-width: 1024px) {
    .newsCard {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .newsCard {
        width: 50%;
    }
}

@media screen and (max-width: 725px) {
    .newsCard {
        width: 100%;
    }
}

.newsModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 51%);
    /* display: none;  */
    z-index: 98;
}

.newsModalContent {
    background: aliceblue;
    height: 70%;
    width: 70%;
    position: fixed;
    top: 20%;
    bottom: 50%;
    right: 15%;
    left: 15%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center; */
    z-index: 99;
    padding: 30px 0;
}
.newsModal__answer {
  
    font-size: 3rem;
    padding: 40px;
}

.newsModal__question {
    padding: 0 40px 40px 40px;
}

.translate {
    cursor: pointer;
    background-color: #003f70;
    color: #fff;
    width: fit-content;
    cursor: pointer;
    padding: .2rem .3rem;
    margin-top: .5rem;
    font-size: 1rem;
    user-select: none;
    display: flex;
    align-items: center;
}

.translate i {
    font-size: 20px;
    display: block;
    font-weight: 400;
    margin-left: .5rem;
}

.wrapperSource {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.translateText {
    font-style: italic;
    margin: .5rem 0;
}