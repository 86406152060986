.burgerToggle {
    font-size: 3rem;
    color: #a3dff9;
    margin-left: 1rem;
}

.main {
    /* height: attr(data-parent); */
    overflow-y: auto;
    position: relative;
    z-index: 99;
}
.wrapper {}

/* HEADER */
.header {
    position: relative;
}

.people {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
    width: 150px;
}

.neiro {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 0;
}

.header_info {
    padding: 1rem 30px;
}

.header_info img {
    width: 120px;
    margin-right: 70px;
}

.info_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.info_title h1 {
    font-size: 1rem;
    color: #9b9b9b;
    width: 60%;
    font-weight: 400;
}

.info_title h2 {
    font-size: 1.5rem;
    font-weight: 400;
}

.header_person {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 64.5%;
    position: relative;
    z-index: -1;
}

.person_block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: start;
    width: 100%;
    background: linear-gradient(90deg, rgb(0, 63, 112) 60%, #edfdff 100%);
    padding-left: 180px;
}

.person_txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 50px;
    color: #fff;
    padding: 0 0 1rem 5px;
}

.person_txt h2 {
    font-size: 18px;
    font-weight: 400;
}

.person_txt h3 {
    font-size: 18px;
    font-weight: 400;
}

.person_avatar {}

.person_avatar img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-top: -5rem;
}

.btn_logOut {
    color: #fff;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    line-height: 18px;
    margin: 1rem 1rem 1.5rem;
    cursor: pointer;
}
/* HEADER */

/* MENU */
.header_menu {
    background-color: #dcf1ff;
}

.menuWrapper {
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.menu_item_wrapper {
    position: relative;
}
.menu_item_wrapper:hover .menu_inner_wrapper {
    display: flex;
}
.menu_item_wrapper:hover .menu_item {
    background-color: #003f70;
    color: #fff;
}

.menu_inner_wrapper {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 100%;
    display: none;
    flex-direction: column;
    background-color: #dcf1ff;
}

.menu_item {
    text-transform: uppercase;
    color: #003f70;
    font-size: 20px;
    line-height: 20px;
    padding: 0.6rem 1rem;
    text-decoration: none;
    text-align: center;
    display: block;
    user-select: none;
    overflow-wrap: anywhere;
}

.menu_inner__item {
    color: #003f70;
    font-size: 15px;
    line-height: 17px;
    padding: 0.6rem 0.5rem;
    text-decoration: none;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    user-select: none;
    overflow-wrap: anywhere;
}
.menu_inner__item:hover {
    background-color: #fff;
    color: grey;
}

.menu_item__active {
    text-transform: uppercase;
    color: #fff;
    background-color: #003f70;
    font-size: 25px;
    padding: 0.5rem 3rem;
}
.menu_item:hover {
    color: #fff;
    background-color: #003f70;
}
/* MENU */

/* FOOTER */
.footer {
    background-color: #003f70;
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 111;
}

.wrapper_links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
}

.version {
    position: absolute;
    top: .7rem;
    right: .3rem;
    color: #fff;
    font-size: 13px;
}

.link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0.5rem;
    cursor: pointer;
}

.link i {
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 0.8rem;
}

.link span {
    font-size: 19px;
}

.FI {
    position: absolute;
    right: 0;
    bottom: 100%;
}
/* FOOTER */
@media screen and (max-width: 1284px) {
    .people {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        /* width: 70%; */
    }

    .header_info {
        padding: 10px 30px;
    }

    .info_title h1 {
        font-size: 20px;
    }

    .person_txt h2 {
        font-size: 16px;
        font-weight: 400;
    }

    .person_txt h3 {
        font-size: 18px;
        font-weight: 400;
    }

    /* .menu_item {
        font-size: 20px;
        line-height: 20px;
        padding: .4rem 0;
    } */

    .header_person {
        width: 60%;
    }

    .btn_logOut {
        margin: 1rem 1rem 0;
        position: absolute;
        right: 0;
        bottom: 1rem;
    }

    .person_block {
        position: relative;
    }

    .menuWrapper {
        width: 100%;
    }

    .person_avatar img {
        width: 110px;
        height: 110px;
        margin-top: -1rem;
    }

    .FI {
        width: 30vw;
        min-width: 200px;
    }

}
@media screen and (max-width: 1024px) {
    .people {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
        /* width: 50%; */
    }

    .neiro {
        opacity: .4;
        z-index: -1;
    }

    .header_person {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .header_person {
        width: 100%;
    }

    .header_info img {
        width: 80px;
        margin-right: 10px;
    }

    .header_info {
        padding: 10px 1rem;
    }

    .person_txt {
        height: 60px;
        padding: 0 0 0 1rem;
    }

    .person_txt h2 {
        font-size: 12px;
    }

    .person_txt h3 {
        font-size: 16px;
    }

    .info_title h1 {
        font-size: 16px;
    }

    .menu_item {
        font-size: 15px;
        line-height: 15px;
    }

    .menu_inner__item {
        font-size: 13px;
        line-height: 15px;
    }

    .menu_inner_wrapper {
        top: 34px;
    }
}

@media screen and (max-width: 529px) {
    
    .info_title h2 {
        font-size: 1rem;
        font-weight: 600;
    }

    .wrapper_links {
        flex-direction: column;
        width: 90%;
    }

    .link {
        width: 100%;
        padding: 0.3rem 0;
        margin: 0;
    }

    .link i {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        margin-right: 0.8rem;
    }

    .link span {
        font-size: 1rem;
    }

    .person_txt {
        height: auto;
        padding: 0.2rem 0 0.4rem 1rem;
    }

    .btn_logOut {
        margin: 1rem 1rem 0;
        position: absolute;
        right: 0;
        bottom: -20px;
    }

    .person_avatar img {
        width: 80px;
        height: 80px;
        margin-top: -1rem;
    }

    .header_person {
        flex-direction: row-reverse;
    }

    .header_menu {
        /* height: calc(100vh - 94px - 68px - 91px - 91px); */
        overflow: auto;
        height: calc(100vh - 220px);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 999;
    }

    .menuWrapper {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .menu_inner_wrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: #dcf1ff;
        top: auto;
    }

    .menu_item_wrapper {
        width: 100%;
    }

    .menu_item {
        text-transform: uppercase;
        color: #003f70;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        padding: 0.6rem 1rem;
        text-decoration: none;
        text-align: left;
        display: block;
        user-select: none;
        overflow-wrap: anywhere;
        width: 100%;
    }

    .menu_inner__item {
        color: #003f70;
        font-size: 15px;
        line-height: 17px;
        padding: 0.6rem 0.5rem 0.6rem 3rem;
        text-decoration: none;
        width: 100%;
        text-align: left;
        transition: 0.3s;
        user-select: none;
        overflow-wrap: anywhere;
    }

}

    @media screen and (max-width: 1284px) {
    .main {
        /* height: calc(100vh - 286px); */
    }
    .wrapper_links {
        width: 100%;
    }
    
}

@media screen and (max-width: 902px) {
    .main {
        /* height: calc(100vh - 362px); */
    }
    .wrapper_links {
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .main {
        /* height: calc(100vh - 342px); */
    }

    .info_title h1 {
        width: 100%;
        font-weight: 600;
    }

    .footer {
        background-color: #003f70;
        width: 100%;
        margin: 0 auto;
        display: flex;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
}

@media screen and (max-width: 529px) {
    .main {
        /* height: calc(100vh - 282px); */
    }
    .link {
        padding: .5rem;
        font-size: 12px;
    }
}

@media screen and (max-width: 529px) {
    .main {
        /* height: calc(100vh - 336px); */
    }
    .link {
        padding: .5rem;
        font-size: 12px;
    }
}

@media screen and (max-width: 386px) {
    .main {
        /* height: calc(100vh - 336px); */
    }
    .link {
        padding: .5rem;
        font-size: 12px;
    }
}

/* @media screen and (max-width: 628px) {
    .main {
        height: calc(100vh - 342px);
    }
}
@media screen and (max-width: 529px) {
    .main {
        height: calc(100vh - 282px);
    }
    .footer {
        padding: 0 .5rem;
    }
}
@media screen and (max-width: 388px) {
    .main {
        height: calc(100vh - 316px);
    }
    .footer {
        padding: 0 .5rem;
    }
} */


/* scroll  */
.header_menu::-webkit-scrollbar,
.main::-webkit-scrollbar,
.menuWrapper div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.header_menu::-webkit-scrollbar-thumb,
.main::-webkit-scrollbar-thumb,
.menuWrapper div::-webkit-scrollbar-thumb {
    height: 95%;
    background-color: rgb(214, 214, 214);
    border-radius: 10px;
}
/* scroll  */

