.wrapper {
    height: 100%;
    width: 100%;
    background-color: #00416f;
    display: flex;
    align-items: start;
    justify-content: center;
}

.map {
    height: 100%;
    width: 70vw;
    margin: 0 auto;
}

.weather {
    width: 100%;
    height: 100%;
}

.react-open-weather-map {
    width: 100%;
    height: 100%;
}

.windy {
    width: 100%;
    height: calc(100% - 4px);
}
.meteo {
    border-radius: 0 0 1rem 1rem;
    width: 150px;
    height: 33px;
    background-color: #dcf1ff;
    position: absolute;
    font-weight: 600;
    text-align: center;
    padding: .5rem 1rem;
    color: #003f70;
}