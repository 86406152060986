.wrapper {
    height: 60px;
    overflow: hidden;
}

.img {
    /* height: 20px;
    width: 20px; */
}

.img {
    /* width: 20%; */
    /* padding: .5rem; */
    cursor: pointer;
}

.bgPopup {
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    height: 100%;   
    display: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup {
    width: 100%;
    max-width: 40vw;
}

.popupImg {
    width: 100%;
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
    margin: 0 auto;
    user-select: none;
}

.close {
    position: absolute;
    z-index: 101;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}