.wrapper {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    height: calc(100% - 64px);
}

.wrapperMap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    width: 35%;
    /* border: 1px solid grey; */
    height: 100%;
    /* padding: .5rem; */
}
.wrapperEvent {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    /* background-color: blue; */
    width: 35%;
    /* border: 1px solid grey; */
    height: 100%;
    /* padding: .5rem; */
}
.wrapperInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: aquamarine; */
    width: 30%;
    /* border: 1px solid grey; */
    height: 100%;
    /* padding: .5rem; */
}

.title {
    font-size: 30px;
    color: #2671a1;
    font-style: italic;
}

.titleMain {
    font-size: 30px;
    display: flex;
    align-items: center;
}

.title_forecast {
    font-size: 30px;
    color: #2671a1;
    font-style: italic;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 10;
}

.forecast {
    width: 100%;
    position: relative;
}

.infoListItem {
    font-size: 18px;
    padding: .2rem;
    color: #2671a1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.infoListItem:hover {
    cursor: pointer;
    text-decoration: underline;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.infoListItem {
    width: 100%;
}

.infoList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    padding-right: .3rem;
}

.infoListItemTitle {
    
}

.resource {
    display: flex;
}

.resource_img {
    margin: .5rem .5rem 0 .5rem;
    color: grey;
    display: block;
}

.chronologyItem {
    display: block;
    margin: .5rem 0;
    font-size: 18px;
    color: grey;
    position: relative;
}

.statInfo {
    margin: .5rem 0;
    font-size: 18px;
    color: grey;
}

.recommendText {
    margin: .5rem 0;
    font-size: 18px;
    color: grey;
}

.planItem {
    margin: .5rem 0;
    font-size: 18px;
    color: grey;
}

.plan {
    width: 100%;
}

.info, .chronology, .recommend, .stat, .plan {
    border: 1px solid grey;
    padding: .5rem;
    position: relative;
}

.blockquoteWrapperRecom {
    overflow-y: auto;
    height: 100%;
    padding-right: 1rem;
}

.recommend {
}

.blockquoteWrapperPlan {
    overflow-y: auto;
    padding-right: 1rem;
}

.blockquoteWrapperState {
    overflow-y: auto;
    height: 100%;
    padding-right: 1rem;
}

.blockquoteWrapper {
    overflow-y: auto;
    height: 100%;
    padding-right: 1rem;
}

.more {
    position: absolute;
    bottom: .2rem;
    right: .2rem;
    color:#2671a1;
    border-bottom: 1px solid #2671a1;
    height: 18px;
    cursor: pointer;
}

.info, .chronology, .plan {
    height: 45%;
}

.map {
    height: 55%;
    width: 100%;
    border: 1px solid grey;
}

.forecast {
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.recommend, .stat {
    width: 100%;
}

.recommend {
    height: 55%;
}

.stat {
    height: 22.5%;
}

.plan {
    height: 22.5%;
}

.chronology {
}

.planList {
    overflow: auto;
    height: 230px;
}

.link {
    color: #2671a1;
    cursor: pointer;
    text-decoration: none;
}

.stat span {
    color: #2671a1;
    border-bottom: 1px solid #2671a1;
    cursor: pointer;
}

.mapsList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.mapsListItem {
    position: relative;
    border: 1px solid grey;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.mapsListItem_second {
    position: relative;
    border: 1px solid grey;
    height: 140px;
    width: 100%;
    margin-right: 2rem;
    overflow: hidden;
}

.arrow {
    position: absolute;
    right: -1.6rem;
    top: 50%;
}

.mapsListItemNumber {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid grey;
    border-radius: 100px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1rem;
    z-index: 10;
}

.mapsListItemHeader {
    text-align: center;
    width: 100%;
    padding: .2rem 0;
    font-weight: 700;
    font-size: 14px;
}

.mapsListItemMap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveIcon {
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: .5rem;
    top: .5rem;
    color: #2671a1;
}

.closeIcon {
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: 3rem;
    top: .5rem;
    color: #2671a1;
}

.editIcon {
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: .5rem;
    top: .5rem;
    color: #2671a1;
}

.addIcon {
    position: absolute;
    right: 3rem;
    top: .5rem;
    color: #2671a1;
    cursor: pointer;
    font-size: 25px;
}

.inputAdd {
    width: 100%;
    cursor: pointer;
    padding: .2rem .5rem;
    outline: none;
    border: 1px solid #2671a1;
}

.wrapper blockquote.active {
    width: 100%;
    display: block;
    border: 1px solid #2671a1;

    margin: .5rem 0;
    font-size: 18px;
    color: grey;
}

.buttonsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    bottom: 1rem;
}

.buttonItem {
    cursor: pointer;
    border: 1px solid #2671a1;
    background-color: #fff;
    color: #2671a1;
    font-size: 10px;
    padding: .5rem;
    margin: .5rem .2rem;
    border-radius: .2rem;
}

.buttonItem_active {
    font-size: 10px;
    cursor: pointer;
    border: 1px solid #2671a1;
    background-color: #2671a1;
    color: #fff;
    padding: .5rem;
    margin: .5rem .2rem;
    border-radius: .2rem;
}

.mainTitle {
    font-size: 20px;
    margin: .5rem 0;
    color: #2671a1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.title_recommend {
    background-color: #fff;
    padding: 0 .2rem;
    color: #003f70;
    position: absolute;
    top: -1rem;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    z-index: 99;
}

.title_info {
    background-color: #fff;
    padding: 0 .5rem;
    color: #003f70;
    position: absolute;
    top: -1rem;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    z-index: 99;
}

.title_chronology {
    background-color: #fff;
    padding: 0 .3rem;
    color: #003f70;
    position: absolute;
    top: -1rem;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    z-index: 99;
}

.title_stat {
    background-color: #fff;
    padding: 0 .2rem;
    color: #003f70;
    position: absolute;
    top: -1rem;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    z-index: 99;
}

.title_plan {
    background-color: #fff;
    padding: 0 .2rem;
    color: #003f70;
    position: absolute;
    top: -1rem;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    z-index: 99;
}

.mainTitleEvent {
    margin: .5rem 1rem 0 1rem;
    width: 80%;
    text-align: center;
}

.mainTitleTime {
    margin: .5rem 0 0 0;
    width: 100%;
    text-align: center;
    position: relative;
}

.mainTitleEvent span {
    font-size: 17px;
    font-weight: 600;
}

.mainTitleTime span {
    text-decoration: underline;
    font-size: 23px;
}

.newsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem;
}

.newsCard {
    width: 49%;
    display: block;
    padding: 1rem;
    cursor: pointer;
    position: relative;
}

.newsCard:hover .delete {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    z-index: 100;
    font-size: 30px;
    color: red;
}

.newsCard:hover {
    background-color: #dcf1ff;
    border-radius: 4px;
    transition: .4s;
    box-shadow: 3.277px 2.294px 8px 0px rgba(171, 171, 171, 0.01);
}

.newsCard h3 {
    font-size: 1rem;
}

.newsCard a {
    display: block;
    margin: 1rem 0;
    color: #4ac2f1;
    font-size: 20px;
}

.newsCard span {
    font-weight: 600;
}

.dateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dateCounter {
    font-size: 20px;
    line-height: 25px;
}

.dateCounter span {
    font-weight: 600;
    font-size: 25px;
}

.inputBlock {
    margin: 1rem;
    position: relative;
}

.inputBlock span {
    position: relative;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #003f70;
    user-select: none;
}

.inputBlock span .plus {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 25px;
    color: #003f70;
    cursor: pointer;
    user-select: none;
}

.inputBlock input {
    font-size: 20px;
    border: 1px solid #003f70;
    padding: 5px 10px;
    cursor: pointer;
    outline: none;
}

.risk {
    margin: .5rem .5rem .5rem 0;
    display: block;
}

.link {
    color: #000 !important;
    text-decoration: none;
}

.link > * {
    margin: .5rem 0;
}

.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    margin: 0 auto;    
}

.button {
    background-color: #003f70;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    margin: 1rem;
}


.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    overflow: auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContent {
    width: 100%;
    max-width: 1200px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem 2rem 0rem 2rem; 
    position: relative;
}

.popupContentInner {
    overflow: auto;
    width: 100%;
    margin-top: 1rem;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 1rem;
    font-size: 20px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.title_forecast {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.popup .inputBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 3rem);
    margin: 1.5rem 0;
    padding: .5rem;
    background: #f9f9f9;
    box-shadow: 3.277px 2.294px 8px 0px rgba(171, 171, 171, 0.01);
    border-radius: 4px;
}

.popup .inputBlockInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);
}

.popup .inputBlock span {
    width: 280px;
}

.popup .inputBlock input {
    width: 100%;
    font-size: 16px;
}

.popup .inputBlock textarea {
    width: 100%;
    resize: none;
    outline: none;
    height: 100px;
    border: 1px solid #003f70;
}

.select {
    width: 60px;
    margin: .5rem;
    padding: .4rem;
    background: #fff;
    border: 1px solid #003f70;
    font-size: 15px;
}

.select_loc {
    width: 100%;
    margin: .5rem;
    padding: .4rem;
    background: #fff;
    border: 1px solid #003f70;
    font-size: 15px;
}

.delete {
    display: none;
}

.wrapperInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputModal {
    margin: .2rem 0;
}

.inputModalEmpty {
    margin: .2rem 0;
    background-color: red !important;
}

.inputRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trash {
    cursor: pointer;
    font-size: 25px;
    color: red;
    margin-left: .5rem;
    user-select: none;
}

.map_select {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 600px;
    height: 300px;
    border: 3px solid #003f70;
    border-radius: 10px;
    overflow: hidden;
}

.mapIcon {
    color: red;
    padding: 0 .5rem;
    cursor: pointer;
    font-size: 20px;
}

.pdfBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    z-index: 100;
    position: absolute;
    right: .5rem;
    top: 0rem;
}

@media screen and (max-width: 1024px) {
    .newsCard {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .newsCard {
        width: 50%;
    }
}

@media screen and (max-width: 725px) {
    .mainTitleEvent {
        margin: 3rem 1rem 0 1rem;
        width: 80%;
        text-align: center;
    }

    .newsCard {
        width: 100%;
    }

    .popup .inputBlock {
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
    }

    .popup .inputBlock span {
        margin-bottom: 1rem;
    }

    .pdfBlock {
        position: relative;
        margin: 1rem auto;
    }
}

.buttonPrint {
    display: flex;
    width: fit-content;
    justify-content: center;
    border-radius: 100px;
    margin-right: 1px;
    cursor: pointer;
    background-color: #003f70;
    color: #fff;
    font-size: 14px;
    padding: .2rem 1rem;
    text-align: center;
}

.selectType {
    width: 100%;
    margin: .5rem;
    padding: .4rem;
    background: #fff;
    border: 1px solid #003f70;
    font-size: 15px;
}


.meteo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.meteoTitle {
    font-size: 20px;
    padding: .5rem .8rem;
    color: #003f70;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 1rem;
    position: absolute;
    top: 0;
    z-index: 99;
    background-color: #fff;
}

.windy {
    width: 100%;
    height: 100%;
}

.switchBlock {
    display: flex;
    align-items: center;
    border: 1px solid #003f70;
    width: fit-content;
    padding: .5rem;
    border-radius: .5rem;
    margin: 0 3rem 0 auto;
}

.switchTitle {
    font-size: 1rem;
}

.control {
    /* position: absolute;
    left: .5rem; */
    color: #fff;
    border-radius: 1rem;
    font-size: 14px;
    background-color: #e03131;
    padding: .2rem 1rem;
}

.questionBlock {
    cursor: pointer;
    font-size: 25px;
    width: 25px;
    height: 25px;
    color: #003f70;
}

.typeRisk {
    position: absolute;
    left: .5rem;
    display: flex;
}

.typeRiskItem {
    background-color: #4ac2f1;
    color: #fff;
    margin-right: 1rem;
    padding: .2rem 1rem;
    border-radius: 1rem;
    font-size: 14px;
}

.typeLevel {
    background-color: #2671a1;
    color: #fff;
    margin-right: 1rem;
    padding: .2rem 1rem;
    border-radius: 1rem;
    font-size: 14px;
}