.li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
}

.li::after {
    position: absolute;
    bottom: 0;
    left: 1rem;
    content: '';
    height: 1px;
    width: calc(100% - 2rem);
    background-color: #D9D9D9;
}

.li:hover {
    background-color: #DEECFF;
}