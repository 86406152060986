.dateWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 88.5vw;
    /* flex-wrap: wrap; */
}

.inputBlock {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.inputBlock span {
    margin-right: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #003f70;
}

.inputBlock input {
    font-size: 20px;
    border: 1px solid #003f70;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.input {
    width: 100%;
    min-width: 280px;
    max-width: 100%;
    outline: none;
    border: 1px solid #003f70;
    padding: .45rem;
    font-size: 15px;
    border-radius: .2rem;
}

.wrapperSelect {
    display: flex;
    /* flex-wrap: wrap; */
}

.select {
    width: fit-content;
    min-width: 280px;
    padding: .4rem;
    background: #fff;
    border: 1px solid #003f70;
    border-radius: .2rem;
    font-size: 15px;
    margin-right: 1rem;
    outline: none;
}

.wrapperSelectItem {
    width: 100%;
}

.wrapperSelectItem span {
    margin-right: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #003f70;
    display: flex;
    flex-direction: column;
}
