.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow-y: auto;
    padding: 0 0 1rem 0;    
}

.emblem {
    position: absolute;
    width: 30%;
    top: 0;
}

.header {
    text-align: center;
    /* height: 220px; */
    width: 100%;
    max-width: 1000px;
}

.header > img {
    margin-top: 2rem;
    width: 200px;
}

.header > h1 {
    margin-top: 2rem;
    font-size: 36px;
    background-image: linear-gradient(45deg, rgb(34, 115, 214), rgb(154, 193, 241));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wrapper main {
    /* height: calc(100vh - 300px); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form > h1 {
    margin-bottom: 3rem;
    color: rgb(58 113 181);
}

.form > div + div {
    margin-top: 1rem;
}

.form button {
    padding: 1rem 7rem;
    margin-top: 2rem;
    border-radius: 1rem;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background: linear-gradient(#158EDB, #3F5F9D, #1986B1);
    user-select: none;
    position: relative;
    cursor: pointer;
}

.form button::before {
    content: "";
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background: linear-gradient(#8DD9F6, #1986B1);
    border-radius: 1.2rem;
    z-index: -1;
}

.form button:hover {
    transform: scale(1.005);
}

.kioskImg {
    position: absolute;
    left: 4rem;
    bottom: 0;
    height: calc(25 * (1vh + 1vw));
    z-index: -1;
}

.chikaImg {
    position: absolute;
    right: 2rem;
    bottom: 5rem;
    z-index: -1;
    height: calc(16 * (1vh + 1vw));
}

.footer {
    padding: 2rem 0 0 0;
    height: 80px;
    width: 100%;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer > div {
    display: flex;
    align-items: center;
}

.footer img {
    width: 1.5rem;
}

.footer span {
    margin-left: 1rem;
    color: #314561;
}

.footer div:nth-child(2) {
    margin-left: 5rem;
}

.version {
    position: absolute;
    top: calc(50% - 9px);
    right: 2rem;
    color: #707174;
}


@media screen and (max-width: 768px) {
    .header {
        padding: 0 1rem;
    }
    
    .header > h1 {
        font-size: 1.5rem;
    }

    .footer div:nth-child(2) {
        margin-left: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .header > h1 {
        font-size: 1rem;
    }
}