.wrapper_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.wrapper {
    padding: 1rem 2rem;
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    height: 50vh;
    position: relative;
}

.close {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    cursor: pointer;
    padding: 1rem;
    font-size: 35px;
}

.listWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
    height: 65%;
    overflow: auto;
}

.file {
    margin: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 3px solid #003f70;
    border-radius: .4rem;
    position: relative;
}

.title {
    font-size: 25px;
    color: #000;
    margin-bottom: .5rem;
}

.close_icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 20px;
}

.file .icon {
    font-size: 40px;
    color: #003f70;
}

.file .name {
    font-size: 20px;
    text-decoration: none;
    color: #003f70; 
    white-space: nowrap;
    width: 100%; 
    max-width: 100px;
    overflow: hidden; 
    text-overflow: ellipsis;
}

.inputFile {
    margin: 1rem 0;
    display: block;
    width: fit-content;
    background-color: rgba(83, 85, 85, 0.082);
    padding: .5rem 1rem;
    font-size: .9rem;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
    font-weight: 500;
}

.inputFile:hover {
    background-color: rgba(83, 85, 85, 0.26);
    color: rgb(255, 255, 255);
    transition: all 0.2s ease-in;
    border-color: rgb(204, 206, 206);
}

.inputFile input[type="file"] {
    display: none;
}

.img {
    width: auto;
    height: 100px;
    object-fit: cover;

}