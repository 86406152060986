.tickerWrap {
  /* position: fixed;
  top: 0; */
  width: 100%;
  overflow: hidden;
  height: 2rem;
  background-color: #dcffd7;
  padding-left: 100%;
  /* box-sizing: content-box; */
  z-index: 999;
}

.tickerWrap .ticker {
  
  display: inline-block;
  height: 2rem;
  line-height: 2rem;  
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  
  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
          -webkit-animation-name: ticker;
         animation-name: ticker;
  -webkit-animation-duration: 50s;
          animation-duration: 50s;
}

.tickerWrap .ticker__item {

  display: inline-block;
  padding: 0 2rem;
  font-size: 1.1rem;

}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
