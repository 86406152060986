.wrapper {
  width: 90%;
  margin: 1rem auto 0;
}

.wrapper > button {
  margin-top: 1.5rem;
}

.wrapper > h2,
.modal > h2 {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #003f70;
}

.wrapper > ul {
  margin: 1rem auto 0;
  width: 90%;
}

.wrapper > ul svg:hover {
  transform: scale(1.3);
  transition: .2s;
}

.firstLiBlock {
  display: flex;
  font-size: 1.1rem;
}

.firstLiBlock {
  width: 50%;
}

.firstLiBlock > div {
  overflow: hidden;
}

.firstLiBlock > div:first-child {
  width: calc(100% - 150px);
}

.modal {
  min-width: 500px;
}

.modal > label {
  display: block;
}

.modal > label + label {
  margin-top: .5rem;
}

.agencyBlock {
  margin-top: .5rem;
}

.agencyBlock > p {
  margin-left: 0.3rem;
    color: #778AAF;
    font-size: 13px;
}

.categoriesBlock {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-width: 75%;
}

.categoriesBlock > div {
  background-color: #003f70;
  color: white;
  padding: .2rem .5rem;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 0 5px #003f70;
  margin: .2rem;
  font-style: italic;
  cursor: pointer;
  position: relative;
}

.catItem svg {
  position: absolute;
  right: 0;
  top: calc(50% - 11px);
  font-size: 22px;
  transition: 0.2s;
  display: none;
}

.catItem svg:hover {
  transform: scale(1.3);
}

.catItem:hover svg {
  display: block;
}

.categoriesBlock > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoriesBlock input {
  width: 100%;
  max-width: 100%;
  border: none;
  outline: none;
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  background-color: transparent;
}


.buttonsBlock {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.buttonsBlock > * + * {
  margin-left: 1rem;
}