.wrapper {
    height: 100%;
}

.listWrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.listItem {
    text-decoration: none;
    font-size: 1.5rem;
    color: #003f70;
    padding: .2rem;
    width: 100%;
    display: block;
}

.itemRow:hover {
    background-color: rgb(243, 243, 243);
    color: #fff;
    transition: .4s;
}

.search {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.searchInput {
    width: 100%;
    font-size: 20px;
    padding: .2rem .5rem;
    margin: 2rem auto;
    outline: none;
}

.wrapperLoader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.button_add {
    background-color: #003f70;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    margin: 0 0 0 auto;
    width: fit-content;
}

.itemRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete {
    cursor: pointer;
    user-select: none;
    font-size: 30px;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}