.wrapper_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.wrapper {
    padding: 1rem 2rem;
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    height: 70vh;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.close {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    cursor: pointer;
    padding: 1rem;
    font-size: 35px;
}

.listWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
    width: 100%;
}

.title {
    font-size: 25px;
    color: #000;
    margin-bottom: .5rem;
}

.textarea {
    width: 100%;
    height: 150px;
    outline: none;
    resize: none;
    font-size: 18px;
    padding: 1rem;
    margin: 1rem 0;
}

.buttomList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.qItem {
    border: 0;
    width: fit-content;
    padding: .5rem;
    background-color: #dcf1ff;
    margin: .5rem;
    padding: .5rem 1rem;
    font-size: 15px;
    border-radius: 100px;
    cursor: pointer;
    box-shadow: 3.277px 2.294px 8px 0px rgba(171, 171, 171, 0.01);
}

.qItem:disabled {
    cursor: no-drop;
}

.qItem:hover {
    transition: .3s;
    background-color: #003f70;
    color: #fff;
}

.wrapperQ {
    margin-top: 1rem;
}

.selectBlock {
    display: flex;  
    align-items: center;
    background-color: #fff;
    border: 1px solid#003f70;
    width: fit-content;
    padding: .5rem;
    margin-left: 2rem;
    height: 53px;
}

.selectBlock span {
    margin-right: 1rem;
    font-size: 1rem;
}

.select {
    background-color: #fff;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    color: #000;
    outline: none; 
    width: fit-content;
}

.select option {
    cursor: pointer;
    color: #000;
}

.buttonBLock {
    display: flex;
    align-items: center;
}

.switchBlock {
    display: flex;
    align-items: center;
    border-top: 1px solid #003f70;
    border-right: 1px solid #003f70;
    border-bottom: 1px solid #003f70;
    width: fit-content;
    padding-left: .5rem;
    height: 53px;
    margin: 0;
}

.switchTitle {
    font-size: 1rem;
}