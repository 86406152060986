.wrapperSelect {
    
}

.wrapperSelect span {
    color: #2671a1;
    font-size: 20px;
    margin-left: 1rem;
}

.select {
    background-color: #dcf1ff;
    border: none !important;
    font-size: 20px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    color: #2671a1;
    font-style: italic;
    outline: none; 
}

.select option {
    cursor: pointer;
    color: #2671a1;
}

select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
  background: #fff !important;
}

select:focus>option:checked {
  background: #fff !important;
}

@media screen and (max-width: 1100px) {
  .wrapperSelect {
    margin: .5rem;
  }
}

@media screen and (max-width: 447px) {
  .wrapperSelect {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .select {
    width: 50vw;
  }
}