.table {
    overflow: auto;
    max-height: 45vh;
    position: relative;
}

.table table {
    border-collapse: collapse;
    border-style: hidden;
    min-width: 100%;
}

.table thead {
    position: sticky;
    top: 0;
    background-color: white;
}

.table tbody tr {
    cursor: pointer;
}

.table tbody tr:hover {
    background-color: #DEECFF;
}

.table th, .table td {
    border: 1px solid #D9D9D9;
    padding: .5rem;
}

.table th {
    color: #778AAF;
    font-weight: 400;
    text-align: center;
}

.table td {
    color: #274684;
    text-align: center;
}