
.wrapper {
  width: 90%;
  margin: 0 auto;
}

.wrapper > h2 {
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #003f70;
}

.wrapper > div {
  width: 70%;
  margin: 1rem auto 0;
  border: 1px solid #D9D9D9;
  max-height: none;
  border-radius: 2px;
}

.wrapper a {
  color: #003f70;
}